'use strict';

const SUCCESS_TYPE_NOTIFICATION = 'success';
const ERROR_TYPE_NOTIFICATION = 'danger';

/**
 * Create an alert to display the alert message
 * @param {Object} message - Message to display
 * @param {string} notificationType - type of notification to print correct alert color
 */
 function createAlertNotification(notificationType ,message) {
    var messageType = 'alert-success';

    if ($('.modifie-product-notes-messages').length === 0) {
        $('body').append(
            '<div class="modifie-product-notes-messages"></div>'
        );
    }

    $('.modifie-product-notes-messages').append(
        '<div class="' + messageType + ' modifie-product-notes-alert text-center" role="alert">'
        + message
        + '</div>'
    );

    setTimeout(function () {
        $('.modifie-product-notes-alert').remove();
    }, 5000);

}

/**
 * Send form with product Notes
 * @return {void} - Do an Ajax call and manage the response
 */
function savePreparationNotes() {
    $('body').on('click', '.btn-confirm-notes, .btn-confirm-notes-pdp', function (e) { 
        e.preventDefault();

        var productID = $(this).data('product-id');
        var selectedForm = $('.formNotes' + productID);
        var closeButton = $('#closeModalNotes' + productID);
        var closeButtonX = $('#closeModalNotesX' + productID);
        var iconNotesSelected = $('#iconNotesSelected' + productID);
        var iconNotes = $('#iconNotes' + productID);
        var modalNotes = '#modalNotes' + productID;

        var url = $(selectedForm).attr('action');
        var formData = $(selectedForm).serialize();

        $('body > .modal-backdrop').remove();

        if (url) {
            $.ajax({
                url: url,
                method: 'post',
                data: formData,
                success: function (response) {
                    // close Modal
                    if (closeButton.length > 0) {
                        $(modalNotes).modal('hide');
                    }

                    // Actualizar el pseudocontext
                    var customerProductNotesElement = $('#customerProductNotes');
                    var customerProductNotesData = customerProductNotesElement.data('customernotes');

                    if (response.code === 'error') {
                        createAlertNotification(ERROR_TYPE_NOTIFICATION, response.message);
                    } else if (response.code === 'success') {
                        createAlertNotification(SUCCESS_TYPE_NOTIFICATION, response.message);
                        
                        var newProductNotes = response.productNotes;

                        var btnConfirmNotes = $(this);
                        var textarea = selectedForm.find('textarea[name="productNotes"]');
                        var hiddenValueInput = selectedForm.find('input[name="value"]');
                        
                        textarea.val(newProductNotes);
                        hiddenValueInput.val(newProductNotes);
                        btnConfirmNotes.attr('data-product-notes', newProductNotes);
                        closeButton.attr('data-product-notes', newProductNotes);
                        closeButtonX.attr('data-product-notes', newProductNotes);

                        if (newProductNotes !== '') {
                            $(iconNotesSelected).removeClass('d-none');
                            $(iconNotes).addClass('d-none');
                            // Actualiza el pseudo contexto
                            customerProductNotesData[productID] = newProductNotes;
                        } else {
                            $(iconNotes).removeClass('d-none');
                            $(iconNotesSelected).addClass('d-none');
                            // Elimina la nota del pseudo contexto
                            delete customerProductNotesData[productID];
                        }

                        // Actualiza el atributo data-customerlists
                        customerProductNotesElement.attr('data-customernotes', JSON.stringify(customerProductNotesData));
                    }
                }
            });
        }
    });
}

function removeNotesIsNotPersist() {
    $('body').on('click', '.btn-close-notes', function (e) {
        e.preventDefault();
        var productID = $(this).data('product-id');
        var modalNotes = '#modalNotes' + productID;
        var iconNotesSelected = $('#iconNotesSelected' + productID);
        $('body > .modal-backdrop').remove();
        if (iconNotesSelected.hasClass('d-none')) {
            $(modalNotes).find('.notesTextareaPLP').val('');
        }
    });
}

/**
 * Makes a CSRF-token research in the twolineheader element to fill the product-notes-form
 * Deletes the previous csrf_token if already exists
 * @param {Element} modalForm - principal div in notes form
 */
function fillCSRFToken(modalForm) {
    var csrfTokenName = $('#customerConnectTk input[type="hidden"]').attr('name');
    var csrfTokenValue = $('#customerConnectTk input[type="hidden"]').val();

    if (csrfTokenName && csrfTokenValue) {
        // Search and deletes previous input (to ignore possible cached elements)
        modalForm.find('input[name="' + csrfTokenName + '"]').remove();
        // Creates new input element
        var csrfInput = $('<input>', {
            type: 'hidden',
            name: csrfTokenName,
            value: csrfTokenValue
        });
        // Append new input element
        modalForm.find('.modal-body').append(csrfInput);
    }
}

$(document).ready(function () {
    var customerProductNotesElement = $('#customerProductNotes');
    var customerProductNotesData = customerProductNotesElement.data('customernotes');

    if (customerProductNotesData) {
        // Verify tiles presence
        var productTiles = $('.product-tile');

        for (var i = 0; i < productTiles.length; i++) {
            var productTile = $(productTiles[i]); // Convierte el elemento DOM a jQuery
            var productId = productTile.closest('.product').data('pid');

            var iconNotesSelected = productTile.find(`#iconNotesSelected${productId}`);
            var iconNotes = productTile.find(`#iconNotes${productId}`);
            if (customerProductNotesData[productId]) {
                var productNote = customerProductNotesData[productId];

                if (iconNotesSelected.length && iconNotes.length) {
                    iconNotesSelected.removeClass('d-none');
                    iconNotes.addClass('d-none');
                }
            } else {
                if (iconNotesSelected.length && iconNotes.length) {
                    iconNotesSelected.addClass('d-none');
                    iconNotes.removeClass('d-none');
                }
            }
            //Falta por incluir los detalles en los formularios...
            //Mejor crear una función y llamarla desdel el save y desde aquí
            //save toma el form en cuestión y aquí la que vaya rotando?
            //Incluir en el document.ready 
        }
    }

    $(document).on('show.bs.modal', '.modal', function () {
        var $modal = $(this);
    
        if ($modal.closest('.recommendations').length) {
            var $form = $modal.closest('form[name="product-notes-form"]');
    
            if ($form.length) {
                $('body').append($form);
            }
        }
    });

    // Iterate over recomendations tiles and add the csrf_token for the Controllers calls
    $(document).on('recommendationsLoaded', function () {
        $('.recommendations .product-tile').each(function () {
            var notesForm = $(this).find('.modal.center-modal-product-notes');
            
            if (notesForm.length) {
                fillCSRFToken(notesForm);
            }
        });
    });

});

module.exports = {
    savePreparationNotes: savePreparationNotes(),
    removeNotesIsNotPersist: removeNotesIsNotPersist()
};
